import React from "react"
import {graphql, StaticQuery} from "gatsby"

export default class Documents extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query DocumentQuery {
                        documentTypes: allDatoCmsDocumentType {
                            edges {
                                node {
                                    title
                                }
                            }
                        }
                        documents: allDatoCmsDocument (
                            sort: {
                            fields: [order]
                                order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    order
                                    title
                                    description
                                    typ {
                                        title
                                    }
                                    file {
                                        url
                                    }
                                }
                            }
                        }
                    }
                `}
                render={({documentTypes, documents}) => (
                    <div>
                        <div className="main-image">
                            <img src="../dokumenty.jpeg" alt="" />
                        </div>
                        <div className="content">
                            {documentTypes.edges.map(({ node: item }) => (
                                <div>
                                    <div className="page-title">
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className="documents">
                                        {documents.edges.filter(({node: document}) => document.typ.title === item.title).map(({node: subitem}, idx) =>(
                                            <div key={idx} className="item">
                                                <i className="icon-pdf" />
                                                <div className="item__content">
                                                    <h5>{subitem.title}</h5>
                                                    <p>{subitem.description}</p>
                                                    <div className="item__actions">
                                                        <a href={subitem.file.url} className="btn-dark btn-dark--sm" title="Pobierz">
                                                            <span>Pobierz</span>
                                                            <i className="icon-download"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            />
        )
    }
}
