import React from "react"
import {Tools} from "../tools"
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"
import * as cn from "classnames"
import Dropdown from "react-bootstrap/Dropdown";

export default class Team extends React.Component {
    state = {
        loading: true,
        activeTab: 1,
        activeTabLabel: "Zarząd Fundacji"
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    changeActiveTab(idx, name) {
        this.setState({activeTab: idx, activeTabLabel: name})
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query TeamQuery {
                        teamTypes: allDatoCmsTeamType {
                            edges {
                                node {
                                    idx
                                    title
                                }
                            }
                        }
                        teamMembers: allDatoCmsTeam  (
                            sort: {
                            fields: [order]
                                order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    image {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                    category {
                                        idx
                                    }
                                    name
                                    title
                                    descriptionNode {
                                        childMarkdownRemark{
                                            html
                                        }
                                    }
                                    email
                                }
                            }
                        }
                    }
                `}
                render={({teamTypes, teamMembers}) => (
                    <div>
                        <div className="main-image">
                            <img src="../zespol.jpeg" alt="" />
                        </div>
                        <div className="content">
                            {this.props.header &&
                                <div className="page-title">
                                    <h3>{this.props.header }</h3>
                                </div>
                            }
                            <div className="main-tabs">
                                {!Tools.isPhone() && teamTypes.edges.map(({ node: item }) => (
                                    <span className={cn(item.idx === s.activeTab ? "active" : "")} key={item.idx} onClick={() => this.changeActiveTab(item.idx, item.title)}>
                                    {item.title}
                                </span>
                                ))}
                                {Tools.isPhone() &&
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        {s.activeTabLabel}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {teamTypes.edges.map(({ node: item }) => (
                                            <Dropdown.Item key={item.idx} onClick={() => this.changeActiveTab(item.idx, item.title)}>{item.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                }
                            </div>
                            <div className="team">
                                {teamMembers.edges.filter(({node: item}) => item.category.idx === s.activeTab).map(({node: subitem}, idx) =>(
                                    <div key={idx} className="team__item">
                                        {subitem.image && <Img fluid={subitem.image.fluid}/>}
                                        <div className="team__content">
                                            <h4>{subitem.name}</h4>
                                            <h6>{subitem.title}</h6>
                                            <pre dangerouslySetInnerHTML={{
                                                __html: subitem.descriptionNode.childMarkdownRemark.html,
                                            }}/>
                                            {subitem.email &&
                                            <a href={`mailto:${subitem.email}`} className="team__mail">
                                                {subitem.email}
                                            </a>
                                            }
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}
