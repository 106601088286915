import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

export default class Contact extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query ContactQuery{
                        contact: datoCmsContactInformation {
                            officeTitle
                            officeAddressNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            officePhone
                            officeEmail
                            officeInfoNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            centerTitle
                            centerAddressNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            centerPhone
                            centerEmail
                            centerInfoNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            infoTitle
                            infoAddressNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            infoPhone
                            infoEmail
                            infoInfoNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                        }
                    }
                `}
                render={({contact}) => (
                    <div className="contact-page">
                        <div className="main-image">
                            <img src="../kontakt.jpeg" alt="" />
                        </div>
                        <div className="contact-section contact-section--first">
                            <div className="content">
                                <img src="../sempre-sm.png" className="logo" alt=""/>
                                <h3>{contact.officeTitle}</h3>
                                <div className="contact-section__row">
                                    <i className="icon-home"></i>
                                    <pre dangerouslySetInnerHTML={{
                                        __html: contact.officeAddressNode.childMarkdownRemark.html,
                                    }}/>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-call"></i>
                                    <p>Telefon: {contact.officePhone}</p>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-email"></i>
                                    <p><a href={`mailto:${contact.officeEmail}`}>{contact.officeEmail}</a></p>
                                </div>
                                {contact.officeInfoNode.childMarkdownRemark.html &&
                                    <pre dangerouslySetInnerHTML={{
                                        __html: contact.officeInfoNode.childMarkdownRemark.html,
                                    }}/>
                                }
                            </div>
                        </div>
                        <div className="contact-section contact-section--third">
                            <div className="content">
                                <h3>{contact.centerTitle}</h3>
                                <div className="contact-section__row">
                                    <i className="icon-home"></i>
                                    <pre dangerouslySetInnerHTML={{
                                        __html: contact.centerAddressNode.childMarkdownRemark.html,
                                    }}/>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-call"></i>
                                    <p>Telefon: {contact.centerPhone}</p>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-email"></i>
                                    <p><a href={`mailto:${contact.centerEmail}`}>{contact.centerEmail}</a></p>
                                </div>
                                {contact.centerInfoNode.childMarkdownRemark.html &&
                                    <pre dangerouslySetInnerHTML={{
                                        __html: contact.centerInfoNode.childMarkdownRemark.html,
                                    }}/>
                                }
                            </div>
                        </div>
                        <div className="contact-section contact-section--first">
                            <div className="content">
                                <h3>{contact.infoTitle}</h3>
                                <div className="contact-section__row">
                                    <i className="icon-home"></i>
                                    <pre dangerouslySetInnerHTML={{
                                        __html: contact.infoAddressNode.childMarkdownRemark.html,
                                    }}/>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-call"></i>
                                    <p>Telefon: {contact.infoPhone}</p>
                                </div>
                                <div className="contact-section__row">
                                    <i className="icon-email"></i>
                                    <p><a href={`mailto:${contact.infoEmail}`}>{contact.infoEmail}</a></p>
                                </div>
                                {contact.infoInfoNode.childMarkdownRemark.html &&
                                <pre dangerouslySetInnerHTML={{
                                    __html: contact.infoInfoNode.childMarkdownRemark.html,
                                }}/>
                                }
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}
