import React from "react"
import {Tools} from "../tools"
import {graphql, Link, StaticQuery} from "gatsby";
import Img from "gatsby-image";

export default class Publications extends React.Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({loading: false})
    }

    render() {
        const s = this.state
        const isPhone = Tools.isPhone()

        return (
            <StaticQuery
                query={graphql`
                    query PublicationsQuery {
                        allDatoCmsPublikacje(
                            sort: {
                            fields: [order]
                                order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    image {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                    title
                                    description
                                    slug
                                    file {
                                        url
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div>
                        <div className="main-image">
                            <img src="../publikacje.jpg" alt="" />
                        </div>
                        <div className="content">
                            {this.props.header &&
                                <div className="page-title">
                                    <h3>{this.props.header }</h3>
                                </div>
                            }
                            <div className="publications">
                                {data.allDatoCmsPublikacje.edges.map(({ node: item, idx }) => (
                                    <div key={idx} className="item">
                                        {item.image && <Img fluid={item.image.fluid} />}
                                        {!item.image && <img src="../no-publication.jpg" className="no-preview" alt=""/>}
                                        <div className="item__content">
                                            <p><strong>{item.title}</strong></p>
                                            {!isPhone && <p>{item.description.slice(0, 140)}...</p>}
                                            {!isPhone &&
                                                <div className="item__actions">
                                                    <a href={`/publications/${item.slug}`} className="btn-dark btn-dark--sm"
                                                       title="Więcej">
                                                        Więcej
                                                    </a>
                                                    <a href={item.file.url} className="btn-yellow btn-yellow--sm"
                                                       title="Pobierz" target="_blank">
                                                        <span>Pobierz</span>
                                                        <i className="icon-download"></i>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        {isPhone &&
                                            <div className="item__bottom">
                                                <p>{item.description.slice(0, 140)}...</p>
                                                <div className="item__actions">
                                                    <a href={`/publications/${item.slug}`} className="btn-dark btn-dark--sm"
                                                       title="Więcej">
                                                        Więcej
                                                    </a>
                                                    <a href={item.file.url} className="btn-yellow btn-yellow--sm"
                                                       title="Pobierz" target="_blank">
                                                        <span>Pobierz</span>
                                                        <i className="icon-download"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}
