import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import AllPosts from "../components/layout/AllPosts"
import Team from "../components/layout/Team"
import Publications from "../components/layout/Publications"
import Documents from "../components/layout/Documents"
import Contact from "../components/layout/Contact"
import {HelmetDatoCms} from "gatsby-source-datocms"
import {graphql} from "gatsby"

export default class Custom extends React.Component {
    render() {
        const p = this.props, { } = p
        const page = p.data.page

        return (
            <Layout>
                <HelmetDatoCms seo={page.seoMetaTags} />
                {page.name === "Blog" &&
                    <AllPosts header={page.header || null} />
                }
                {page.name === "Zespół" &&
                    <Team header={page.header || null} />
                }
                {page.name === "Dokumenty" &&
                    <Documents />
                }
                {page.name === "Publikacje" &&
                    <Publications header={page.header || null} />
                }
                {page.name === "Kontakt" &&
                    <Contact />
                }
            </Layout>
        )
    }
}

export const query = graphql`
  query CustomQuery($slug: String!) {
    page: datoCmsCustomPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      slug
      header
    }
  }
`