import React from "react"
import * as cn from "classnames"
import {graphql, Link, StaticQuery} from "gatsby"
import Img from "gatsby-image"

export default class AllPosts extends React.Component {
    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query ArticlesQuery {
                        allDatoCmsArticle (
                            sort: {
                            fields: [created]
                                order: DESC
                            }
                        ) {
                            edges {
                                node {
                                    mainImage {
                                        fluid(imgixParams: { auto: "compress" }) {
                                            ...GatsbyDatoCmsSizes
                                        }
                                    }
                                    title
                                    slug
                                    draft
                                    contentNode {
                                        childMarkdownRemark{
                                            html
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div className="content">
                        {this.props.header &&
                            <div className="page-title">
                                <h3>{this.props.header }</h3>
                            </div>
                        }
                        <div className="blog-preview blog-preview--all">
                            <div className="blog-preview__content">
                                {data.allDatoCmsArticle.edges.filter(({node: article}) => article.draft !== true).map(({ node: item, idx }) => (
                                    <Link to={`/blog/${item.slug}`} key={idx} className="item">
                                        <span className="item__img">
                                            {item.mainImage && <Img fluid={item.mainImage.fluid} />}
                                            {!item.mainImage && <img src="../no-article.jpg" className="no-preview" alt=""/>}
                                        </span>
                                        <span className="item__content">
                                            <h5>{item.title}</h5>
                                             <p dangerouslySetInnerHTML={{
                                                 __html: item.contentNode.childMarkdownRemark.html.slice(0, 240) + "..."
                                             }}/>
                                            <span className="text-center">
                                                <span to={`/blog/${item.slug}`} className="btn-dark">
                                                    <span>Więcej</span>
                                                    <i className="icon-arrow-right"></i>
                                                </span>
                                            </span>
                                        </span>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}